import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "46",
  height: "46",
  viewBox: "0 0 46 46",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M24.0961 22.178H0.5V45.774H24.0961V22.178Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F06F02",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M33.9006 11.3375H26.3484V7.724H37.5141V19.0463H33.9006V11.3375Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F06F02",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M36.5393 11.0974L25.7229 21.9138L23.1678 19.3587L33.9841 8.5423L36.5393 11.0974Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M7.19696 0.226013H11.1718V3.8395H10.8104V4.15944H7.19696V0.226013ZM15.508 0.226013H19.8442V3.8395H15.508V0.226013ZM24.1804 0.226013H28.5166V3.8395H24.1804V0.226013ZM32.8528 0.226013H37.1889V3.8395H32.8528V0.226013ZM41.5251 0.226013H45.5V4.15944H41.8865V3.8395H41.5251V0.226013ZM7.19696 12.6662V8.41282H10.8104V12.6662H7.19696ZM45.5 8.41282V12.6662H41.8865V8.41282H45.5ZM7.19696 21.1729V16.9196H10.8104V21.1729H7.19696ZM45.5 16.9196V21.1729H41.8865V16.9196H45.5ZM7.19696 29.6797V25.4263H10.8104V29.6797H7.19696ZM45.5 25.4263V29.6797H41.8865V25.4263H45.5ZM7.19696 33.9331H10.8104V34.253H11.1718V37.8665H7.19696V33.9331ZM45.5 33.9331V37.8665H41.5251V34.253H41.8865V33.9331H45.5ZM19.8442 37.8665H15.508V34.253H19.8442V37.8665ZM28.5166 37.8665H24.1804V34.253H28.5166V37.8665ZM37.1889 37.8665H32.8528V34.253H37.1889V37.8665Z"
    }, null, -1)
  ])))
}
export default { render: render }